.custom-modal {
  .ant-modal-content {
    width: 100vw;
    height: 100vh;
    margin: 0;
    border-radius: 0;
  }

  .ant-modal-body {
    overflow: auto;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .ant-modal {
    top: 0 !important;
    position: relative;
    margin: 0;
    padding: 0;
    width: 100vw !important;
  }
}
